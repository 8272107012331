<template>
  <div class="wrapper">
      <div>
          <img src="@/assets/images/nosotros/background_illustration_NOSOTROS.svg" alt="">
      </div>
      <div class="content">
          <h1>Sobre Nosotros</h1>
          <p>
            VVMóvil, somos una empresa comprometida con el medio ambiente, unimos nuestros conocimientos y esfuerzos para promover activamente entre la población una cultura ambiental reflexiva, que impulse acciones enfocadas a la protección del medio ambiente, el control y prevención de la contaminación del aire. 
          </p>
          <p>
           VVMóvil no persigue excluir o sustituir los centros de verificación fijos, nuestro propósito es concientizar a la población de la importancia de que su vehículo cumpla con los estándares establecidos y para ello nosotros brindamos una opción práctica, flexible y segura, que ayudará a cada habitante y Gobierno de nuestro país a preservar lo más importante, nuestra calidad de vida, salud y bienestar común. 
          </p>
          <!-- <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec
            odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.
            Suspendisse urna nibh, <strong>viverra non</strong>, semper suscipit, posuere a,
            pede.
          </p> -->
          <router-link to="/contacto" ><button class="router-btn">Contacto</button></router-link>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Nosotros',
  mounted() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content{
    text-align: left;
    margin-bottom: 150px;
}
</style>
